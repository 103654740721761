import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import Moment from 'react-moment';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import Layout from '@components/layout';
import { TicketingPopup } from '../components/ui/ticketingPopup';

import { Arrow } from '@svg/arrow';

const OtherEvents = ({ currentSlug, sortedEvents, setPopupOpen }) => {
  let count = 0;
  return (
    <div className='grid grid-cols-14 mt-24 lg:mt-64 lg:pb-48 mb-16 lg:mb-0'>
      <div className='col-start-2 col-end-14 grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-12'>
        {sortedEvents.map((otherEvent, i) => {
          const {
            title,
            session,
            slug,
            ticketsLink,
            where,
            listingImage,
            freeEvent,
            hideTicketingInfo,
            series,
            buttonText,
          } = otherEvent || {};
          return (
            slug?.current !== currentSlug?.current && (
              <div key={i} className='@container prose md:prose-lg text-black'>
                <div className='border-b border-grey w-full'>
                  <p>{series}</p>
                  <h2 className='font-medium'>{title}</h2>
                </div>

                <div className='border-b border-grey w-full grid grid-cols-10'>
                  <p className='col-span-3 lg:col-span-2'>When:</p>

                  <div className='col-start-5 lg:col-start-4 col-span-6'>
                    {session.map((date, i) => {
                      return (
                        <p key={i}>
                          <Moment withTitle format='ddd DD MMM YYYY - h:mm A'>
                            {date}
                          </Moment>
                        </p>
                      );
                    })}
                  </div>
                </div>

                <div className='border-b border-grey w-full grid grid-cols-10'>
                  <p className='col-span-3 lg:col-span-2'>Where:</p>
                  <div className='col-start-5 lg:col-start-4 col-span-7'>
                    <p>{where?.title}</p>
                  </div>
                </div>

                {ticketsLink && (
                  <div className='border-b border-grey w-full grid grid-cols-10 items-center'>
                    <p className='col-span-3 lg:col-span-2'>Concert info:</p>
                    <Link
                      to={`/events/${slug && slug?.current}`}
                      className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-5'>
                      Read more
                    </Link>
                  </div>
                )}

                {ticketsLink && !hideTicketingInfo && (
                  <div className='border-b border-grey w-full grid grid-cols-10 items-center'>
                    <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
                    <p
                      onClick={() => setPopupOpen(true)}
                      className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 cursor-pointer'>
                      Single Concert vs Series Packages
                    </p>
                  </div>
                )}
                {freeEvent && !hideTicketingInfo && (
                  <div className='border-t border-grey w-full grid grid-cols-10 items-center'>
                    <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
                    <p className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 '>
                      Free Admission
                    </p>
                  </div>
                )}

                <div className='mt-8 mb-8 lg:mb-0 lg:mt-14 flex justify-start flex-col gap-x-0 @[460px]:gap-x-3 @[460px]:flex-row'>
                  <div className='inline-flex'>
                    <Link
                      to={`/events/${slug?.current}`}
                      className='btn-color-white btn-white'>
                      Concert Info
                    </Link>
                  </div>
                  {buttonText !== 'Hidden' && (
                    <div className='inline-flex'>
                      <Link
                        to={
                          ticketsLink
                            ? ticketsLink
                            : '/concert-series-subscription'
                        }
                        target={ticketsLink ? '_blank' : '_self'}
                        rel={ticketsLink ? 'noreferrer' : ''}
                        className='btn-color-black btn-black'>
                        {buttonText}
                      </Link>
                    </div>
                  )}
                </div>

                <div className='lg:mt-16 overflow-hidden'>
                  <Link to={`/events/${slug?.current}`}>
                    {listingImage && (
                      <Image
                        className='transform hover:scale-105 duration-300 ease-in-out m-0-important'
                        {...listingImage?.image}
                      />
                    )}
                  </Link>
                </div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

const Filter = ({ filters, setFilters }) => {
  return (
    <>
      <AnimatePresence initial={false}>
        {filters.expanded && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            className='col-start-2 col-end-14'>
            <motion.div
              variants={{
                collapsed: { opacity: 0, scale: 1 },
                open: { opacity: 1, scale: 1 },
              }}
              transition={{ duration: 0.8 }}
              className='pt-10 lg:pt-16 grid grid-cols-1 lg:grid-cols-3 pb-0 lg:pb-16'>
              <div className='col-span-1 flex flex-col items-start'>
                <span className='pb-3 border-b border-grey w-full mb-3 text-base leading-base md:text-xl md:leading-xl text-left'>
                  Date
                </span>
                <button
                  onClick={() => setFilters({ ...filters, dates: 'all' })}
                  className={`${
                    filters.dates === 'all' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base text-left focus:outline-none`}>
                  All Dates
                </button>
                <button
                  onClick={() => setFilters({ ...filters, dates: 'week' })}
                  className={`${
                    filters.dates === 'week' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  This Week
                </button>
                <button
                  onClick={() => setFilters({ ...filters, dates: 'month' })}
                  className={`${
                    filters.dates === 'month' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  This Month
                </button>
                <button
                  onClick={() => setFilters({ ...filters, dates: 'year' })}
                  className={`${
                    filters.dates === 'year' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  This Year
                </button>
              </div>

              <div className='col-span-1 flex flex-col items-start mt-6 lg:mt-0'>
                <span className='pb-3 border-b border-grey w-full mb-3 text-base leading-base md:text-xl md:leading-xl text-left'>
                  Series
                </span>
                <button
                  onClick={() => setFilters({ ...filters, series: 'all' })}
                  className={`${
                    filters.series === 'all' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base text-left focus:outline-none`}>
                  All Series
                </button>
                <button
                  onClick={() =>
                    setFilters({ ...filters, series: 'international' })
                  }
                  className={`${
                    filters.series === 'international' &&
                    'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  International Series
                </button>
                <button
                  onClick={() => setFilters({ ...filters, series: 'martinee' })}
                  className={`${
                    filters.series === 'martinee' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  Matinee Series
                </button>
              </div>

              <div className='col-span-1 flex flex-col items-start mt-6 lg:mt-0'>
                <span className='pb-3 border-b border-grey w-full mb-3 text-base leading-base md:text-xl md:leading-xl text-left'>
                  Venue
                </span>
                <button
                  onClick={() => setFilters({ ...filters, venue: 'all' })}
                  className={`${
                    filters.venue === 'all' && 'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base text-left focus:outline-none`}>
                  All Venues
                </button>
                <button
                  onClick={() =>
                    setFilters({ ...filters, venue: 'Dunedin Town Hall' })
                  }
                  className={`${
                    filters.venue === 'Dunedin Town Hall' &&
                    'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  Dunedin Town Hall
                </button>
                <button
                  onClick={() =>
                    setFilters({
                      ...filters,
                      venue: 'King’s and Queen’s Performing Arts Centre',
                    })
                  }
                  className={`${
                    filters.venue ===
                      'King’s and Queen’s Performing Arts Centre' &&
                    'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  King’s and Queen’s Performing Arts Centre
                </button>
                <button
                  onClick={() =>
                    setFilters({ ...filters, venue: 'Hanover Hall' })
                  }
                  className={`${
                    filters.venue === 'Hanover Hall' &&
                    'font-semibold underline'
                  } text-sm leading-sm md:text-base md:leading-base mt-2 text-left focus:outline-none`}>
                  Hanover Hall
                </button>
              </div>
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

const EventPortal = ({ pageContext, data: { events } }) => {
  {
    /* Performer carousel */
  }
  const [index, setIndex] = useState(0);

  function handleNext() {
    if (index === artists.length - 1) {
      return setIndex(0);
    }
    return setIndex((prev) => prev + 1);
  }

  function handlePrev() {
    if (index === 0) {
      return setIndex(artists.length - 1);
    }
    return setIndex((prev) => prev - 1);
  }

  {
    /* Filtering */
  }
  const [filters, setFilters] = useState({
    expanded: true,
    venue: 'all',
    series: 'all',
    dates: 'all',
  });

  const [sortedEvents, setSortedEvents] = useState(
    events.nodes.sort(
      (a, b) => new Date(a.session[0]) - new Date(b.session[0]),
    ),
  );
  const {
    title,
    isOneOff,
    listingImage,
    slug,
    where,
    session,
    repertoire,
    settings,
    ticketsLink,
    artists,
    freeEvent,
    hideTicketingInfo,
    series,
    buttonText,
  } = sortedEvents[0] || {};

  useEffect(() => {
    var now = new Date();
    const thisWeek = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 7,
    );
    const thisMonth = new Date(
      now.getFullYear(),
      now.getMonth() + 1,
      now.getDate(),
    );
    const thisYear = new Date(
      now.getFullYear() + 1,
      now.getMonth(),
      now.getDate(),
    );

    let filteredEvents = events.nodes
      .filter(
        (event) => new Date(event.session[event.session.length - 1]) > now,
      )
      .sort((a, b) => new Date(a.session[0]) - new Date(b.session[0]));

    if (filters.series !== 'all') {
      filteredEvents = filteredEvents.filter(
        (event) => event.seriesCategory === filters.series,
      );
    }
    if (filters.venue !== 'all') {
      filteredEvents = filteredEvents.filter(
        (event) => event.where.title === filters.venue,
      );
    }

    if (filters.dates === 'week') {
      filteredEvents = filteredEvents.filter(
        (event) =>
          new Date(event.session[event.session.length - 1]) > now &&
          new Date(event.session[event.session.length - 1]) < thisWeek,
      );
    }
    if (filters.dates === 'month') {
      filteredEvents = filteredEvents.filter(
        (event) =>
          new Date(event.session[event.session.length - 1]) > now &&
          new Date(event.session[event.session.length - 1]) < thisMonth,
      );
    }
    if (filters.dates === 'year') {
      filteredEvents = filteredEvents.filter(
        (event) =>
          new Date(event.session[event.session.length - 1]) > now &&
          new Date(event.session[event.session.length - 1]) < thisYear,
      );
    }

    setSortedEvents(filteredEvents);
  }, [filters]);

  //popup
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <>
      <style>
        {`\
                .event-rollover{\
                    background-color: ${settings && settings.bgColor.hex}\
                }\
            `}
      </style>

      <Layout>
        <div className='text-black'>
          <div className='pb-20 pt-28 lg:pt-64 event-rollover grid grid-cols-14 lg:pb-28'>
            <div className='col-start-2 col-end-14'>
              <h1 className='title-block'>Events Calendar</h1>
              <button
                className='btn-black mt-4 lg:mt-8 text-lg leading-lg focus:outline-none'
                onClick={() =>
                  setFilters({ ...filters, expanded: !filters.expanded })
                }>
                {filters.expanded ? 'Hide Filter' : 'Show Filter'}
              </button>
            </div>

            <Filter filters={filters} setFilters={setFilters} />

            {sortedEvents.length === 0 ? (
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  key={sortedEvents.length}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className='text-center w-full py-40 col-start-2 col-end-14'>
                  <span className='title-block'>No events found</span>
                </motion.div>
              </AnimatePresence>
            ) : (
              <div className='col-start-2 col-end-14 lg:grid grid-cols-1 lg:grid-cols-2 gap-x-20 mt-20'>
                <AnimatePresence exitBeforeEnter>
                  {artists[index] && (
                    <motion.div
                      key={artists[index].name}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className='relative row-start-2 row-end-3 lg:row-start-1 lg:row-end-2 col-span-1 flex items-center justify-center mt-16 lg:mt-0 aspect-h-4 aspect-w-3'>
                      {artists[index].bioImage ? (
                        <Image
                          className='object-cover'
                          {...artists[index].bioImage.image}
                        />
                      ) : (
                        <StaticImage src={'@images/missing.jpg'} />
                      )}

                      <div className='absolute flex flex-row justify-between items-end artist-gradient'>
                        <div className='text-white mb-6 ml-6'>
                          <span className='block text-base leading-base md:text-2xl md:leading-2xl'>
                            {artists[index].name}
                          </span>
                          <span className='block text-sm leading-sm md:text-lg md:leading-lg capitalize'>
                            {artists[index].position.name}
                          </span>
                        </div>

                        <div className='mr-6 flex flex-row gap-x-8'>
                          <button
                            onClick={() => handlePrev(index)}
                            className='py-10 focus:outline-none'
                            style={{ transform: 'scaleX(-1)' }}>
                            <Arrow white={true} />
                          </button>
                          <button
                            onClick={() => handleNext(index)}
                            className='py-10 focus:outline-none'>
                            <Arrow white={true} />
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                {!artists[index] && (
                  <div className='lg:row-start-1 col-start-2 col-end-3 pt-28 pb-28 '>
                    <Image
                      className='w-full   h-auto'
                      {...listingImage.image}
                    />
                  </div>
                )}

                <div className='@container prose md:prose-lg col-span-1 flex flex-col justify-center text-black'>
                  <div className='mt-8 lg:mt-16'>
                    <div className='border-b border-grey w-full lg:pb-10'>
                      <span className='text-sm leading-sm md:text-lg md:leading-lg -mb-4 flex'>
                        {series}
                      </span>
                      <h2 className='font-medium'>{title}</h2>
                      <h5>{settings.searchExcerpt}</h5>
                    </div>

                    <div className='border-b border-grey w-full grid grid-cols-10'>
                      <p className='col-span-3 lg:col-span-2'>Programme:</p>
                      <div className='col-start-5 lg:col-start-4 col-span-7'>
                        {repertoire.map((row, i) => {
                          return (
                            <div
                              className={`block ${
                                (i === 0 && 'mt-1 lg:mt-4') ||
                                (i === repertoire.length - 1 && 'mb-1 lg:mb-4')
                              }`}>
                              <span
                                key={i}
                                className='font-semibold text-sm leading-sm md:text-lg md:leading-lg'>
                                {row.heading}{' '}
                              </span>
                              <p className='inline'>{row.text}</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='border-b border-grey w-full grid grid-cols-10'>
                      <p className='col-span-3 lg:col-span-2'>When:</p>

                      <div className='col-start-5 lg:col-start-4 col-span-6'>
                        {session.map((date, i) => {
                          return (
                            <p key={i}>
                              <Moment
                                withTitle
                                format='ddd DD MMM YYYY - h:mm A'>
                                {date}
                              </Moment>
                            </p>
                          );
                        })}
                      </div>
                    </div>

                    <div className='border-b border-grey w-full grid grid-cols-10'>
                      <p className='col-span-3 lg:col-span-2'>Where:</p>
                      <p className='col-start-5 lg:col-start-4 col-span-5'>
                        {where.title}
                      </p>
                    </div>

                    {ticketsLink && (
                      <div className='border-b border-grey w-full grid grid-cols-10 items-center'>
                        <p className='col-span-3 lg:col-span-2'>
                          Concert info:
                        </p>
                        <Link
                          to={`/events/${slug && slug?.current}`}
                          className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-5'>
                          Read more
                        </Link>
                      </div>
                    )}

                    {ticketsLink && !hideTicketingInfo && (
                      <div className='border-b border-grey w-full grid grid-cols-10 items-center'>
                        <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
                        <p
                          onClick={() => setPopupOpen(true)}
                          className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 cursor-pointer'>
                          Single Concert vs Series Packages
                        </p>
                      </div>
                    )}
                    {freeEvent && !hideTicketingInfo && (
                      <div className='border-t border-grey w-full grid grid-cols-10 items-center'>
                        <p className='col-span-3 lg:col-span-2'>Ticketing:</p>
                        <p className='underline-important font-semibold col-start-5 lg:col-start-4 col-span-7 '>
                          Free Admission
                        </p>
                      </div>
                    )}

                    <div className='mt-8 flex-col gap-y-3 gap-x-0 @[460px]:gap-x-3 @[460px]:flex-row lg:mt-14 flex justify-start'>
                      <div className='inline-flex'>
                        <Link
                          to={`/events/${slug?.current}`}
                          className='btn-white'>
                          Concert Info
                        </Link>
                      </div>
                      {!isOneOff && buttonText !== 'Hidden' && (
                        <div className='inline-flex'>
                          <Link
                            to={
                              ticketsLink
                                ? ticketsLink
                                : '/concert-series-subscription'
                            }
                            target={ticketsLink ? '_blank' : '_self'}
                            rel={ticketsLink ? 'noreferrer' : ''}
                            className='btn-color-black btn-black'>
                            {buttonText}
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {sortedEvents.length > 1 && (
            <OtherEvents
              currentSlug={sortedEvents[0].slug}
              sortedEvents={sortedEvents}
              setPopupOpen={setPopupOpen}
            />
          )}
        </div>
      </Layout>

      <TicketingPopup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
    </>
  );
};

export default EventPortal;

export const eventQuery = graphql`
  query eventPortalQuery($skip: Int!, $limit: Int!) {
    events: allSanityEvent(skip: $skip, limit: $limit) {
      nodes {
        where {
          id
          location
          title
        }
        venueLink
        title
        freeEvent
        hideTicketingInfo
        isOneOff
        ticketsLink
        buttonText
        slug {
          current
        }
        settings {
          bgColor {
            hex
          }
          reverseHeader
          revereseText
          searchExcerpt
        }
        session
        series
        seriesCategory
        repertoire {
          text
          heading
        }
        listingImage {
          image {
            ...ImageWithPreview
          }
        }
        artists: featuredArtists {
          bioImage {
            image {
              ...ImageWithPreview
            }
          }
          name
          position {
            name
          }
          id
        }
        additionalInfo {
          heading
          text
        }
        id
        meta {
          _key
          _type
          metaTitle
          metaDescription
          _rawMetaImage
        }
      }
    }
  }
`;
